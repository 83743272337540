import type { DehydratedState, FetchQueryOptions, QueryClientConfig } from '@tanstack/react-query';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import SuperJSON from 'superjson';

import { config } from '@endaoment-frontend/config';
import { TIME_ONE_MINUTE_IN_SECONDS } from '@endaoment-frontend/constants';

import { isFetchError } from './index';

// Creates new instance of QueryClient - useful to repeat to avoid caching between SSG/server requests
export const newQueryClient = (additionalConfig: QueryClientConfig = {}) =>
  new QueryClient({
    ...additionalConfig,
    defaultOptions: {
      ...additionalConfig.defaultOptions,
      queries: {
        // Force refetch of queries after 30 minutes
        gcTime: 30 * TIME_ONE_MINUTE_IN_SECONDS * 1000,
        // Consider data stale after 10 seconds
        staleTime: 10 * 1000,
        refetchInterval: config.environmentName === 'demo' ? 30_000 : undefined,
        retry: (n, e) => {
          if (
            isFetchError(e) &&
            e.statusCode &&
            // We do not want to retry on 4xx errors
            e.statusCode >= 400 &&
            e.statusCode < 500 &&
            // The exception is 408, which is a timeout
            e.statusCode !== 408
          )
            return false;
          return n < 3;
        },
        retryDelay: n => 1000 * Math.pow(10, n),
        refetchOnWindowFocus: false,
        ...additionalConfig.defaultOptions?.queries,
      },
      mutations: {
        retry: (n, e) => {
          if (
            isFetchError(e) &&
            e.statusCode &&
            // We do not want to retry on 4xx errors
            e.statusCode >= 400 &&
            e.statusCode < 500 &&
            // The exception is 408, which is a timeout
            e.statusCode !== 408
          )
            return false;
          return n < 3;
        },
        retryDelay: n => 1000 * Math.pow(5, n),
        ...additionalConfig.defaultOptions?.mutations,
      },
    },
  });

// QueryClient instance for use only on the server-side
export const queryClientForSSR = newQueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 0,
    },
  },
});

export const defaultQueryClient = newQueryClient();

// Execute a series of queries, used in getStaticProps to make a dehydrated QueryClient
export const makeDehydratedQueries = async (...queries: Array<FetchQueryOptions>): Promise<string> => {
  const queryClient = newQueryClient({ defaultOptions: { queries: { retry: false, staleTime: 0 } } });
  await Promise.all(
    queries.map(queryArgs => {
      try {
        return queryClient.prefetchQuery(queryArgs);
      } catch {
        throw new Error(`Error prefetching query: ${queryArgs.queryKey.toString()}`);
      }
    }),
  );

  return SuperJSON.stringify(dehydrate(queryClient));
};

export const convertDehydratedStringToState = (dehydratedState: string | undefined): DehydratedState | undefined => {
  if (!dehydratedState) return undefined;
  return SuperJSON.parse(dehydratedState);
};
