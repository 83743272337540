import type { IconProps } from './Icon.types';

export const LockIcon = ({ width = 22, height, color = '#696F8C', ...props }: IconProps) => (
  <svg
    width={width}
    height={height ?? width}
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.5 8.42308C5.94772 8.42308 5.5 8.87079 5.5 9.42308V17.5C5.5 18.0523 5.94771 18.5 6.5 18.5H15.5C16.0523 18.5 16.5 18.0523 16.5 17.5V9.42308C16.5 8.87079 16.0523 8.42308 15.5 8.42308H6.5ZM11 15C11.8284 15 12.5 14.3284 12.5 13.5C12.5 12.6716 11.8284 12 11 12C10.1716 12 9.5 12.6716 9.5 13.5C9.5 14.3284 10.1716 15 11 15Z'
      fill={color}
      fillOpacity='0.25'
    />
    <path
      d='M6.25 9.42308C6.25 9.28501 6.36193 9.17308 6.5 9.17308V7.67308C5.5335 7.67308 4.75 8.45658 4.75 9.42308H6.25ZM6.25 17.5V9.42308H4.75V17.5H6.25ZM6.5 17.75C6.36193 17.75 6.25 17.6381 6.25 17.5H4.75C4.75 18.4665 5.5335 19.25 6.5 19.25V17.75ZM15.5 17.75H6.5V19.25H15.5V17.75ZM15.75 17.5C15.75 17.6381 15.6381 17.75 15.5 17.75V19.25C16.4665 19.25 17.25 18.4665 17.25 17.5H15.75ZM15.75 9.42308V17.5H17.25V9.42308H15.75ZM15.5 9.17308C15.6381 9.17308 15.75 9.28501 15.75 9.42308H17.25C17.25 8.45658 16.4665 7.67308 15.5 7.67308V9.17308ZM6.5 9.17308H15.5V7.67308H6.5V9.17308ZM11.75 13.5C11.75 13.9142 11.4142 14.25 11 14.25V15.75C12.2426 15.75 13.25 14.7426 13.25 13.5H11.75ZM11 12.75C11.4142 12.75 11.75 13.0858 11.75 13.5H13.25C13.25 12.2574 12.2426 11.25 11 11.25V12.75ZM10.25 13.5C10.25 13.0858 10.5858 12.75 11 12.75V11.25C9.75736 11.25 8.75 12.2574 8.75 13.5H10.25ZM11 14.25C10.5858 14.25 10.25 13.9142 10.25 13.5H8.75C8.75 14.7426 9.75736 15.75 11 15.75V14.25ZM8.25 8.42308V6.57692H6.75V8.42308H8.25ZM8.25 6.57692V6.5H6.75V6.57692H8.25ZM13.75 6.5V6.57692H15.25V6.5H13.75ZM13.75 6.57692V8.42308H15.25V6.57692H13.75ZM11 3.75C12.5188 3.75 13.75 4.98122 13.75 6.5H15.25C15.25 4.15279 13.3472 2.25 11 2.25V3.75ZM11 2.25C8.65279 2.25 6.75 4.15279 6.75 6.5H8.25C8.25 4.98122 9.48122 3.75 11 3.75V2.25Z'
      fill={color}
    />
  </svg>
);
