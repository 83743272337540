import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocalStorage } from 'react-use';

import { useIsMounted } from '@endaoment-frontend/hooks';

import { Button } from './Button';
import { cardClassNames } from './Card';
import styles from './DemoEnvironmentToast.module.scss';

export const DemoEnvironmentToast = () => {
  const [isModalOpen, setModalOpen] = useLocalStorage('demoEnvironmentToastOpen', true);
  const isMounted = useIsMounted();

  return (
    <AnimatePresence initial>
      {!!isModalOpen && !!isMounted && (
        <motion.div
          className={clsx(cardClassNames.base, styles['demo-environment-toast'])}
          initial={{ x: '-100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}>
          <div>
            <h3>Demo Environment</h3>
            <p>
              You are currently viewing the application in a demo environment, not a production instance. Donations and
              grants will automatically fulfill.
            </p>
            <Button onClick={() => setModalOpen(false)}>Close</Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
