export enum NDAO_WALLETS {
  ZACH = '0x9ba4a587E757c8C6E3e2063c8634fC1D24F4a675',
  ROBBIE = '0x2C4B47668eA298EF4eF98956774Ea590E130ceFA',
  PEDRO = '0x5398785700a9248cfc2101f0188c9788e8619974',
  RICARDO = '0x77dfb7afae21622274751358d01ad5f1d59d202a',
  NOAH = '0xc8b810fe39952aa65ca5a9387a3546b9b6bf5780',
  TEST_PAUSER_WALLET = '0xbb77d2c8c6fec55285c782356333afdcd4a926d8',
  BRUXA = '0xe17b2975e5c6646f6aeaf49b4e19d2567c517833',
}

export const DOT_ORG_TEAM = [NDAO_WALLETS.ROBBIE, NDAO_WALLETS.ZACH, NDAO_WALLETS.BRUXA] as const;
