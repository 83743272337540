import { useSyncExternalStore } from 'react';

export const useIsMounted = () => {
  const isMounted = useSyncExternalStore(
    () => {
      return () => {
        // Nothing
      };
    },
    () => typeof window !== 'undefined',
    () => false,
  );

  return isMounted;
};
