import { z } from 'zod';

import { einSchema, timestampSchema, uuidSchema } from '../general';
import { addressSchema, bigIntSchema } from '../web3';

import { adminFundSchema } from './funds';
import { entityLabelSchema, entityTypeSchema, stockLotSchema } from './misc';
import { orgSimpleListingSchema, subprojectListingSchema } from './orgs';
import { evmTokenSchema, otcTokenSchema } from './tokens';

const donationEntityTypeSchema = z.enum([...entityTypeSchema.options, 'subproject']);

const donationRecipientOrgSchema = z.object({
  type: z.literal('org'),
  einOrId: z.union([einSchema, uuidSchema]),
  subprojectId: uuidSchema.optional(),
});
export type DonationRecipientOrg = z.infer<typeof donationRecipientOrgSchema>;
const donationRecipientFundSchema = z.object({
  type: z.literal('fund'),
  id: uuidSchema,
});
export type DonationRecipientFund = z.infer<typeof donationRecipientFundSchema>;
export const donationRecipientSchema = z.union([donationRecipientOrgSchema, donationRecipientFundSchema]);
export type DonationRecipient = z.infer<typeof donationRecipientSchema>;

export const donationSchema = z.object({
  id: uuidSchema,
  chainId: z.number(),
  entityType: donationEntityTypeSchema,
  destinationEntity: uuidSchema,
  transactionHash: addressSchema,
  destinationContractAddress: addressSchema,
  donorUserId: uuidSchema.nullish(),
  donorAddress: addressSchema,
  /** A Currency unit value */
  inputAmount: bigIntSchema,
  // TODO: Split into union types for inputToken and inputStock
  inputToken: evmTokenSchema.nullish(),
  /** A USDC value */
  netOutputBaseToken: bigIntSchema,
  /** A USDC value */
  fee: bigIntSchema,
  outputBaseToken: evmTokenSchema,
  createdAtUtc: timestampSchema,
});
export type Donation = z.infer<typeof donationSchema>;
export const isDonation = (obj: unknown): obj is Donation => donationSchema.safeParse(obj).success;

const adminDonationDestinationOrgSchema = donationSchema.extend({
  entityType: z.literal('org'),
  destinationOrg: orgSimpleListingSchema,
});
const adminDonationDestinationFundSchema = donationSchema.extend({
  entityType: z.literal('fund'),
  destinationFund: adminFundSchema,
});
const adminDonationDestinationSubprojectSchema = donationSchema.extend({
  entityType: z.literal('subproject'),
  destinationSubproject: subprojectListingSchema,
});
export const adminDonationSchema = z.union([
  adminDonationDestinationOrgSchema,
  adminDonationDestinationFundSchema,
  adminDonationDestinationSubprojectSchema,
]);
export type AdminDonation = z.infer<typeof adminDonationSchema>;

export const supportedBrokerSchema = z.object({
  name: z.string().min(1, { message: 'Required' }),
  label: z.string().min(1, { message: 'Required' }),
});
export type SupportedBroker = z.infer<typeof supportedBrokerSchema>;

export const pledgeOutcomeSchema = z.enum(['Pending', 'AwaitingAssets', 'OnRamping', 'Success', 'Failure']);
export type PledgeOutcome = z.infer<typeof pledgeOutcomeSchema>;

const genericDonationPledgeDetailsSchema = z.object({
  id: uuidSchema,
  outcome: pledgeOutcomeSchema,
  destinationEntity: entityLabelSchema,
  netDonationUsdc: bigIntSchema.nullish(),
  protocolFeesUsdc: bigIntSchema.nullish(),
});
const cryptoDonationPledgeDetailsSchema = genericDonationPledgeDetailsSchema.extend({
  type: z.literal('CryptoDonationPledge'),
  inputToken: z.union([otcTokenSchema, evmTokenSchema]),
});

export const stockDonationPledgeDetailsSchema = genericDonationPledgeDetailsSchema.extend({
  type: z.literal('StockDonationPledge'),
  inputStock: z.object({ name: z.string(), ticker: z.string() }),
  // Coerce shares to number since BE returns a string
  shares: z.number({ coerce: true }),
  lots: stockLotSchema.array().optional(),
});
export type StockDonationPledgeDetails = z.infer<typeof stockDonationPledgeDetailsSchema>;

export const stripePaymentTypeSchema = z.enum([
  'Card',
  'Bank Transfer',
  'Apple Pay',
  'Cash App',
  'Google Pay',
  'PayPal',
  'Other',
]);
export type StripePaymentType = z.infer<typeof stripePaymentTypeSchema>;
export const stripeDonationPledgeDetailsSchema = genericDonationPledgeDetailsSchema.extend({
  type: z.literal('StripeDonationPledge'),
  stripePaymentIntentId: z.string(),
  selectedPaymentMethod: stripePaymentTypeSchema.nullish(),
  pledgedAmountCents: z.number(),
  stripeFeesCents: z.number(),
});
export type StripeDonationPledgeDetails = z.infer<typeof stripeDonationPledgeDetailsSchema>;

export const cashDonationPledgeDetailsSchema = genericDonationPledgeDetailsSchema.extend({
  type: z.literal('CashDonationPledge'),
  reason: z.enum(['DafMigration', 'WirePledge']),
});

export const custodianCashPledgeDetailsSchema = genericDonationPledgeDetailsSchema.extend({
  type: z.literal('CustodianCashPledge'),
  custodianName: z.string(),
});

export const migrationPledgeDetailsSchema = genericDonationPledgeDetailsSchema.extend({
  type: z.literal('CashDonationPledge'),
});
export const donationPledgeDetailsSchema = z.union([
  cryptoDonationPledgeDetailsSchema,
  stripeDonationPledgeDetailsSchema,
  cashDonationPledgeDetailsSchema,
  custodianCashPledgeDetailsSchema,
  stockDonationPledgeDetailsSchema,
  migrationPledgeDetailsSchema,
]);
export type DonationPledgeDetails = z.infer<typeof donationPledgeDetailsSchema>;
