import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import clsx from 'clsx';
import Image, { type StaticImageData } from 'next/image';
import Link from 'next/link';
import type { ReactNode } from 'react';
import { useState } from 'react';

import { InfoIcon, PopupIcon } from '@endaoment-frontend/ui/icons';

import styles from './DefinitionPopover.module.scss';

export const DefinitionPopover = ({
  children,
  title,
  definition,
  learnMoreHref,
  shot,
}: {
  children: ReactNode;
  title: string;
  definition: ReactNode;
  learnMoreHref: string;
  shot?: StaticImageData;
}) => {
  const [triggered, setTriggered] = useState(false);
  return (
    <Popover
      trigger='hover'
      placement='top'
      openDelay={100}
      closeDelay={150}
      arrowSize={50}
      onOpen={() => setTriggered(true)}
      onClose={() => setTriggered(false)}>
      <PopoverTrigger>
        <span className={styles['trigger']} data-triggered={triggered}>
          {children}
          <svg viewBox='0 0 300 100' preserveAspectRatio='none'>
            <path d='M0 100H300' vectorEffect='non-scaling-stroke' />
          </svg>
        </span>
      </PopoverTrigger>
      <PopoverContent
        className={styles['definition']}
        variants={{
          exit: {
            scale: 0.75,
            translateY: 10,
            opacity: 0,
          },
          enter: {
            scale: 1,
            translateY: 0,
            opacity: 1,
          },
        }}>
        <PopoverArrow />
        <PopoverBody className={styles['body']}>
          <h4>
            <InfoIcon color='currentColor' width={17} height={17} />
            {title}
          </h4>
          <div>
            <div>
              <p>{definition}</p>
              <Link href={learnMoreHref} target='_blank' className={styles['learn-more']}>
                Learn more <PopupIcon color='currentColor' width={15} height={15} />
              </Link>
            </div>
            {!!shot && (
              <span>
                <Image className={clsx(triggered && styles['zoom'])} src={shot} alt='' width={300} height={300} />
              </span>
            )}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
