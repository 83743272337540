import { z } from 'zod';

import { timestampSchema, uuidSchema } from '../general';
import { addressSchema, bigIntSchema } from '../web3';

import { entityTypeSchema } from './misc';

export const portfolioTypeSchema = z.enum([
  'AaveUsdc',
  'CompoundUsdc',
  'YearnUsdc',
  'AaveUsdcV3',
  'CompoundUsdcV3',
  'SingleToken',
  'TPlusN',
  'PrivateWealth',
  'IlliquidAsset',
  'IlliquidSellOverTime',
  'IlliquidLockedAdvisorManaged',
]);
export type PortfolioType = z.infer<typeof portfolioTypeSchema>;

export const portfolioCategorySchema = z.object({
  id: uuidSchema,
  name: z.string(),
  description: z.string(),
  logoUrl: z.string(),
  colorRgb: z.string(),
});
export type PortfolioCategory = z.infer<typeof portfolioCategorySchema>;

export const portfolioSchema = z.object({
  id: uuidSchema,
  chainId: z.number(),
  contractAddress: addressSchema,
  type: portfolioTypeSchema,
  name: z.string(),
  description: z.string(),
  shortDescription: z.string().nullish(),
  shortName: z.string().nullish(),
  logoUrl: z.string(),
  /** A USDC value */
  cap: bigIntSchema.nullish(),
  /** A basis point value, 100 bp = 1% */
  redemptionFeeBps: z.number(),
  /** A basis point value, 100 bp = 1% */
  depositFeeBps: z.number(),
  enabled: z.boolean(),
  underlyingTokenContractAddress: addressSchema.nullish(),
  aipPerSecondFeesWad: bigIntSchema.nullish(),
  minDeposit: bigIntSchema.nullish(),
  minRedeemableShares: bigIntSchema.nullish(),
  categories: z.array(portfolioCategorySchema),
  underlyingStockTicker: z.string().nullish(),
  providerFeeBps: z.number().nullish(),
  isExclusive: z.boolean(),
  completed: z.boolean().nullish(),
});
export type Portfolio = z.infer<typeof portfolioSchema>;

export const portfolioFinancialSchema = portfolioSchema.extend({
  /** A USDC value */
  totalInvestedInPortfolio: bigIntSchema,
  positionCount: z.number(),
  currentApyBps: z.number().nullish(),
  quarterlyPerformanceBps: z.number().nullish(),
  updatedAt: timestampSchema.nullish(),
});
export type PortfolioFinancial = z.infer<typeof portfolioFinancialSchema>;

export const entityPositionSchema = z.object({
  portfolio: portfolioFinancialSchema,
  /** A USDC value */
  currentMarketValue: bigIntSchema,
  /** A USDC value */
  inTransitBuyUsdcAmount: bigIntSchema,
  /** A USDC value */
  inTransitSellUsdcAmount: bigIntSchema,
});
export type EntityPosition = z.infer<typeof entityPositionSchema>;
export const entityPositionSummarySchema = z.object({
  entityName: z.string(),
  entityType: entityTypeSchema,
  entityLogo: z.string().nullish(),
  entityId: uuidSchema,
  /** A USDC value */
  totalNetAssetsValue: bigIntSchema,
  positions: z.array(entityPositionSchema),
});
export type EntityPositionSummary = z.infer<typeof entityPositionSummarySchema>;

export const portfolioPositionSummarySchema = z.object({
  entities: z.array(entityPositionSummarySchema),
  /** A USDC value */
  totalNetAssetsValue: bigIntSchema,
  /** A USDC value */
  totalInvested: bigIntSchema,
});
export type PositionsSummary = z.infer<typeof portfolioPositionSummarySchema>;

export const portfolioHistoryPointSchema = z.object({
  timestamp: timestampSchema,
  value: z.number(),
});
export type PortfolioHistoryPoint = z.infer<typeof portfolioHistoryPointSchema>;
